import { Card, CardBody } from '@paljs/ui/Card';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { GlobalStateData } from '../storage/GlobalDataProvider';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import '../styles/print.css';
import { PrintContext } from './transaction/job-card';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  dateFormat,
  checkNumber,
  baseUri,
  showLoader,
  hideLoader,
  randomKey,
  numFormatter,
  authCheck,
  appendErrorMsg,
  removeErrorMsg,
  dateGreaterOrEqual,
  idprcoptions,
} from '../utils/utils';

<style>
  {/* header,
.menu-sidebar {
    display: none;
} */}
</style>;

const PrintJobCard = (props) => {
  const closeScreen = useContext(PrintContext);
  const [id, setId] = useState(props.printId);
  const [tableData, setTableData] = useState([]);
  const [defaultIdPrcTypeLabel, setDefaultIdPrcTypeLabel] = useState('');

  const authState = useContext(GlobalStateData);

  useEffect(() => {
    authCheck(authState.authData);
    getApi(id);
  }, []);

  const triggerPrint = () => {
    window.print();
  };

  const getApi = (id) => {
    showLoader();
    axios
      .get(
        baseUri() +
          `job-cards/${id}?populate[0]=processInstruction&populate[1]=processInstruction.customer&populate[2]=processInstruction.customerSort&populate[3]=processOrder&populate[4]=processOrder.customer&populate[5]=processOrder.customersSort&populate[6]=processedGreys&populate[7]=processedGreys.greyReceiptAdvice&populate[8]=currentGroup&populate[9]=currentOperation&populate[10]=nextGroup&populate[11]=nextOperation&populate[12]=processInstruction.piOperations&populate[13]=processInstruction.piOperations.piConsumables&populate[14]=processInstruction.piOperations.piConsumables.item&populate[15]=processInstruction.piOperations.operation&populate[16]=processOrder.graIds&populate[17]=processInstruction.customerSort.sort&populate[18]=processOrder.customersSort.sort`,
        {
          headers: {
            authorization: 'Bearer ' + authState.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((res) => {
        setTableData(res.data.data);
        if (res.data.data.attributes.idPrcType) {
          let filteredType = idprcoptions.filter((data) => {
            return data.value == res.data.data.attributes.idPrcType;
          });
          setDefaultIdPrcTypeLabel(filteredType[0].label);
        }
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  return (
    <>
      <div className="printbtncontainer">
        <Button status="Success" type="button" onClick={closeScreen} className="btnrad backtolist" shape="" halfWidth>
          <EvaIcon name="arrow-back" /> Back To List
        </Button>
        <Button status="danger" type="button" onClick={triggerPrint} className="btnrad printscreen" shape="" halfWidth>
          <EvaIcon name="printer" /> Print
        </Button>
      </div>
      <div className="print-container">
        <div className="print-header p5">
          <h5>UNITED BLEACHERS (P) LTD.,</h5>
          <div className="print-div-part3">
            <h6 className="bb0">Mettupalayam - 641 301.</h6>
            <h6 className="bb0 ">
              ID Lot Num:
              <span className="big-style">{tableData.attributes && tableData.attributes.idLotNum}</span>
            </h6>
            <h6 className="bb0">
              Prc Type:
              <span>{defaultIdPrcTypeLabel}</span>
            </h6>
          </div>
          <div className="print-div-part3">
            <h6 className="bb0"></h6>
          </div>
          <div className="print-div-part3">
            <h6 className="bb0">
              JCN:
              <span>{tableData.attributes && tableData.id}</span>
            </h6>
            <h6 className="bb0">
              {' '}
              Job Card Date: <span>{tableData.attributes && dateFormat(tableData.attributes.jobCardDate)}</span>{' '}
            </h6>
          </div>
        </div>

        {tableData.attributes && tableData.attributes.processInstruction.data ? (
          <>
            <div className="print-header">
              <div className="print-div-part1">
                <h6 className="bb0 t-center">Process Instruction</h6>
              </div>
            </div>
            <div className="print-header">
              <div className="print-div-part3 ">
                <h6 className="p5">
                  PI Number / Date:
                  <span>
                    {tableData.attributes && tableData.attributes.processInstruction.data
                      ? tableData.attributes.processInstruction.data.attributes.piNumber
                      : null}
                    /
                    {tableData.attributes && tableData.attributes.processInstruction.data
                      ? dateFormat(tableData.attributes.processInstruction.data.attributes.piDate)
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Customer:
                  <span>
                    {tableData.attributes && tableData.attributes.processInstruction.data
                      ? tableData.attributes.processInstruction.data.attributes.customer.data.attributes.userCode +
                        ' - ' +
                        tableData.attributes.processInstruction.data.attributes.customer.data.attributes.name
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Sample Received:
                  <span>
                    {tableData.attributes && tableData.attributes.processInstruction.data
                      ? tableData.attributes.processInstruction.data.attributes.sampleReceived
                        ? 'Yes'
                        : 'No'
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Blend 1:
                  <span>
                    {tableData.attributes && tableData.attributes.processInstruction.data
                      ? tableData?.attributes?.processInstruction?.data.attributes?.customerSort?.data?.attributes?.sort
                          ?.data?.attributes?.blend1 +
                        ' - ' +
                        tableData?.attributes?.processInstruction?.data.attributes?.customerSort?.data?.attributes?.sort
                          ?.data?.attributes?.blendPercent1
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Blend 2:
                  <span>
                    {tableData.attributes && tableData.attributes.processInstruction.data
                      ? tableData?.attributes?.processInstruction?.data.attributes?.customerSort?.data?.attributes?.sort
                          ?.data?.attributes?.blend2 +
                        ' - ' +
                        tableData?.attributes?.processInstruction?.data.attributes?.customerSort?.data?.attributes?.sort
                          ?.data?.attributes?.blendPercent2
                      : null}
                  </span>
                </h6>
                <h6 className="p5 bb0">
                  Blend 3:
                  <span>
                    {tableData.attributes && tableData.attributes.processInstruction.data
                      ? tableData?.attributes?.processInstruction?.data.attributes?.customerSort?.data?.attributes?.sort
                          ?.data?.attributes?.blend3 +
                        ' - ' +
                        tableData?.attributes?.processInstruction?.data.attributes?.customerSort?.data?.attributes?.sort
                          ?.data?.attributes?.blendPercent3
                      : null}
                  </span>
                </h6>
              </div>
              <div className="print-div-part3  bl2 br2">
                <h6 className="p5">
                  Shade:
                  <span>
                    {tableData.attributes && tableData.attributes.processInstruction.data
                      ? tableData.attributes.processInstruction.data.attributes.piColor
                      : null}
                  </span>
                </h6>
                <h6 className="p5 ">
                  Warp:
                  <span>
                    {tableData.attributes && tableData.attributes.processInstruction.data
                      ? tableData?.attributes?.processInstruction?.data.attributes?.customerSort?.data?.attributes?.sort
                          ?.data?.attributes?.warp
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Weft:
                  <span>
                    {tableData.attributes && tableData.attributes.processInstruction.data
                      ? tableData?.attributes?.processInstruction?.data.attributes?.customerSort?.data?.attributes?.sort
                          ?.data?.attributes?.weft
                      : null}
                  </span>
                </h6>
                <h6 className="p5 ">
                  Reed:
                  <span>
                    {tableData.attributes && tableData.attributes.processInstruction.data
                      ? tableData?.attributes?.processInstruction?.data.attributes?.customerSort?.data?.attributes?.sort
                          ?.data?.attributes?.reed
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Pick:
                  <span>
                    {tableData.attributes && tableData.attributes.processInstruction.data
                      ? tableData?.attributes?.processInstruction?.data.attributes?.customerSort?.data?.attributes?.sort
                          ?.data?.attributes?.pick
                      : null}
                  </span>
                </h6>
                <h6 className="p5 bb0">
                  Grey Lmtr / Kg:
                  <span>{tableData.attributes && tableData.attributes.greyWeightPerMtr}</span>
                </h6>
              </div>
              <div className="print-div-part3 ">
                <h6 className="p5">
                  Sort:
                  <span>
                    {tableData.attributes && tableData.attributes.processInstruction.data
                      ? tableData.attributes.processInstruction.data.attributes.customerSort.data.attributes.sortName
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Grey Linear Meter:
                  <span>{tableData?.attributes?.totalMtrsToBeProcessed}</span>
                </h6>
                <h6 className="p5">
                  Grey Weight:
                  <span>
                    {(
                      (tableData.attributes.totalMtrsToBeProcessed * tableData.attributes.greyWeightPerMtr) /
                      1000
                    ).toFixed(2)}
                  </span>
                </h6>
                <h6 className="p5">
                  Grey Width:
                  <span>
                    {tableData.attributes && tableData.attributes.processInstruction.data
                      ? tableData?.attributes?.processInstruction?.data.attributes?.customerSort?.data?.attributes?.sort
                          ?.data?.attributes?.greyWidth
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Finished Width:
                  <span>
                    {tableData.attributes && tableData.attributes.processInstruction.data
                      ? tableData.attributes.processInstruction.data.attributes.finishedWidth
                      : null}
                  </span>
                </h6>
                <h6 className="p5 bb0">
                  Finished GLM:
                  <span>
                    {tableData.attributes && tableData.attributes.processInstruction.data
                      ? tableData.attributes.processInstruction.data.attributes.finishedGLM
                      : null}
                  </span>
                </h6>
              </div>
            </div>

            {tableData.attributes &&
              tableData.attributes.processInstruction.data.attributes.piOperations.map((op, i) => (
                <div key={randomKey()}>
                  <div className="print-header">
                    <div className="print-div-part2 br2">
                      <h6 className="p5 bb0">
                        Operation:
                        <span>
                          {op.operation.data
                            ? op.operation.data.attributes.operationCode +
                              ' - ' +
                              op.operation.data.attributes.description
                            : ''}
                        </span>
                      </h6>
                    </div>
                    <div className="print-div-part2 ">
                      <h6 className="p5 bb0">
                        Hours:
                        <span>{op.pioHours}</span>
                      </h6>
                    </div>
                  </div>
                  {/* <div className="print-header">
                    <div className="print-div-part4 br2">
                      <h6 className="p5 bb0">Item</h6>
                    </div>
                    <div className="print-div-part4 br2">
                      <h6 className="p5 bb0">GPL Qty</h6>
                    </div>
                    <div className="print-div-part4 ">
                      <h6 className="p5 bb0">GPL in %</h6>
                    </div>
                  </div> */}
                  {/* {op.piConsumables &&
                    op.piConsumables.map((row2, j) => (
                      <>
                        <div className="print-header" key={randomKey()}>
                          <div className="print-div-part4 br2">
                            <h6 className="p5 bb0">
                              {row2.item.data.attributes.itemCode + ' - ' + row2.item.data.attributes.itemName}
                            </h6>
                          </div>
                          <div className="print-div-part4 br2">
                            <h6 className="p5 bb0">{row2.pctQtyInGPL}</h6>
                          </div>
                          <div className="print-div-part4 ">
                            <h6 className="p5 bb0">{row2.pctQtyInPercentage}</h6>
                          </div>
                        </div>
                      </>
                    ))} */}
                </div>
              ))}
          </>
        ) : (
          ''
        )}

        {tableData.attributes && tableData.attributes.processOrder.data ? (
          <>
            <div className="print-header">
              <div className="print-div-part1">
                <h6 className="bb0 t-center">Process Order Confirmation</h6>
              </div>
            </div>
            <div className="print-header">
              <div className="print-div-part3 br2">
                <h6 className="p5 ">
                  POC No. / Date:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.id
                      : null}
                    /
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? dateFormat(tableData.attributes.processOrder.data.attributes.poDate)
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Customer:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.customer.data.attributes.userCode +
                        ' - ' +
                        tableData.attributes.processOrder.data.attributes.customer.data.attributes.name
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Sample Received:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.status
                        ? 'Yes'
                        : 'No'
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Blend 1:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData?.attributes?.processOrder?.data.attributes?.customersSort?.data?.attributes?.sort
                          ?.data?.attributes?.blend1 +
                        ' - ' +
                        tableData?.attributes?.processOrder?.data.attributes?.customersSort?.data?.attributes?.sort
                          ?.data?.attributes?.blendPercent1
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Blend 2:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData?.attributes?.processOrder?.data.attributes?.customersSort?.data?.attributes?.sort
                          ?.data?.attributes?.blend2 +
                        ' - ' +
                        tableData?.attributes?.processOrder?.data.attributes?.customersSort?.data?.attributes?.sort
                          ?.data?.attributes?.blendPercent2
                      : null}
                  </span>
                </h6>
                <h6 className="p5 bb0">
                  Blend 3:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData?.attributes?.processOrder?.data.attributes?.customersSort?.data?.attributes?.sort
                          ?.data?.attributes?.blend3 +
                        ' - ' +
                        tableData?.attributes?.processOrder?.data.attributes?.customersSort?.data?.attributes?.sort
                          ?.data?.attributes?.blendPercent3
                      : null}
                  </span>
                </h6>
              </div>
              <div className="print-div-part3 br2">
                <h6 className="p5">
                  Shade:
                  <span></span>
                </h6>
                <h6 className="p5 ">
                  Warp:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData?.attributes?.processOrder?.data.attributes?.customersSort?.data?.attributes?.sort
                          ?.data?.attributes?.warp
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Weft:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData?.attributes?.processOrder?.data.attributes?.customersSort?.data?.attributes?.sort
                          ?.data?.attributes?.weft
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Reed:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData?.attributes?.processOrder?.data.attributes?.customersSort?.data?.attributes?.sort
                          ?.data?.attributes?.reed
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Pick:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData?.attributes?.processOrder?.data.attributes?.customersSort?.data?.attributes?.sort
                          ?.data?.attributes?.pick
                      : null}
                  </span>
                </h6>
                <h6 className="p5 bb0">
                  Grey GLM:
                  <span>{tableData.attributes && tableData.attributes.greyWeightPerMtr}</span>
                </h6>
              </div>
              <div className="print-div-part3 ">
                <h6 className="p5">
                  Sort:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.customersSort.data.attributes.sortName
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Grey Linear Meter:
                  <span>{tableData?.attributes?.totalMtrsToBeProcessed}</span>
                </h6>
                <h6 className="p5">
                  Grey Weight:
                  <span>
                    {(
                      (tableData.attributes.totalMtrsToBeProcessed * tableData.attributes.greyWeightPerMtr) /
                      1000
                    ).toFixed(2)}
                  </span>
                </h6>
                <h6 className="p5">
                  Grey Width:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData?.attributes?.processOrder?.data.attributes?.customersSort?.data?.attributes?.sort
                          ?.data?.attributes?.greyWidth
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Finished Width:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.finishWidth
                      : null}
                  </span>
                </h6>
                <h6 className="p5 bb0">
                  Finished GLM:
                  <span>
                    {(
                      tableData.attributes.processOrder.data.attributes.finishGSM /
                      tableData.attributes.processOrder.data.attributes.finishWidth
                    ).toFixed(2)}
                  </span>
                </h6>
              </div>
            </div>

            <div className="print-header">
              <div className="print-div-part4 br2">
                <h6 className="p5">
                  Total Order Qty:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.orderQty
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Bleached Qty:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.bleachedQty
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Dyed Qty:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.dyedQty
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Coating Qty:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.coatingQty
                      : null}
                  </span>
                </h6>
                <h6 className="p5 bb0">
                  Others Qty:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.othersQty
                      : null}
                  </span>
                </h6>
              </div>
              <div className="print-div-part4 br2">
                <h6 className="p5">
                  Total Finished Qty:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.finishedQty
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Finished Bleached Qty:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.bleachedFinishedQty
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Finished Dyed Qty:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.dyedFinishedQty
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Finished Coating Qty:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.coatingFinishedQty
                      : null}
                  </span>
                </h6>
                <h6 className="p5 bb0">
                  Finished Others Qty:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.othersFinishedQty
                      : null}
                  </span>
                </h6>
              </div>
              <div className="print-div-part4 br2">
                <h6 className="p5">
                  Wash Fastness:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.washFastness
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Rubbing Fastness Dry:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.rubbingFastnessDry
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Shrinkage Warp:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.shrinkageWarp
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Grey GSM:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.greyGSM
                      : null}
                  </span>
                </h6>
                <h6 className="p5 bb0">
                  Piece Length:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.pieceLength
                      : null}
                  </span>
                </h6>
              </div>
              <div className="print-div-part4 ">
                <h6 className="p5">
                  Light Fastness:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.lightFastness
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Rubbing Fastness Wet:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.rubbingFastnessWeft
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Shrinkage Weft:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.shrinkageWeft
                      : null}
                  </span>
                </h6>
                <h6 className="p5">
                  Finish GSM:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.finishGSM
                      : null}
                  </span>
                </h6>
                <h6 className="p5 bb0">
                  Exp. Date:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? dateFormat(tableData.attributes.processOrder.data.attributes.deliveryDate)
                      : null}
                  </span>
                </h6>
              </div>
            </div>
            <div className="print-header">
              <div className="print-div-part2 br2">
                <h6 className="p5 mh60">
                  Process:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.process.map((record, ind) =>
                          record.checked ? (
                            <>
                              <span key={randomKey()}>{record.name}</span>,{' '}
                            </>
                          ) : null,
                        )
                      : null}
                  </span>
                </h6>
                <h6 className="p5 mh60 bb0">
                  Finish required:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.finishRequired.map((record, ind) =>
                          record.checked ? (
                            <>
                              <span key={randomKey()}>{record.name}</span>,{' '}
                            </>
                          ) : null,
                        )
                      : null}
                  </span>
                </h6>
              </div>
              <div className="print-div-part2">
                <h6 className="p5 mh60">
                  Process required:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.processRequired.map((record, ind) =>
                          record.checked ? (
                            <>
                              <span key={randomKey()}>{record.name}</span>,{' '}
                            </>
                          ) : null,
                        )
                      : null}
                  </span>
                </h6>
                <h6 className="p5 mh60 bb0">
                  Packing Requirement:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.packing.map((record, ind) =>
                          record.checked ? (
                            <>
                              <span key={randomKey()}>{record.name}</span>,{' '}
                            </>
                          ) : null,
                        )
                      : null}
                  </span>
                </h6>
              </div>
            </div>

            <div className="print-header">
              <div className="print-div-part2">
                <h6 className="p5 bb0">
                  Stamping:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.stamping
                      : null}
                  </span>
                </h6>
              </div>
            </div>
            <div className="print-header">
              <div className="print-div-part1 p0">
                <h5 className="p5 bb0">
                  Remarks:
                  <span>
                    {tableData.attributes && tableData.attributes.processOrder.data
                      ? tableData.attributes.processOrder.data.attributes.remarks.map((record) => (
                          <span style={{ whiteSpace: 'pre-wrap' }} key={randomKey()}>
                            {record.value}
                          </span>
                        ))
                      : null}
                  </span>
                </h5>
              </div>
            </div>
          </>
        ) : (
          ''
        )}

        <div className="print-header">
          <div className="print-div-part1">
            <h6 className="bb0 t-center">JOB CARD DETAILS</h6>
          </div>
        </div>
        <div className="print-header">
          <div className="print-div-part1">
            <h6 style={{ textAlign: 'left', fontSize: '14px' }} className="bb0">
              GRA Process
            </h6>
          </div>
        </div>
        <div className="print-header">
          <div className="print-div-part4 br2">
            <h6 className="p5 bb0">Grey Receipt</h6>
          </div>
          <div className="print-div-part4 br2">
            <h6 className="p5 bb0">Meters Processed</h6>
          </div>
          <div className="print-div-part4 ">
            <h6 className="p5 bb0">Bales Processed</h6>
          </div>
        </div>

        {tableData.attributes &&
          tableData.attributes.processedGreys.data.map((row, i) => (
            <>
              <div className="print-header" key={randomKey()}>
                <div className="print-div-part4 br2">
                  <h6 className="p5 bb0">{row.attributes.greyReceiptAdvice.data.attributes.receiptNumber}</h6>
                </div>
                <div className="print-div-part4 br2">
                  <h6 className="p5 bb0">{row.attributes.metersProcessed}</h6>
                </div>
                <div className="print-div-part4 ">
                  <h6 className="p5 bb0">{row.attributes.balesProcessed}</h6>
                </div>
              </div>
            </>
          ))}

        <div className="print-header">
          <div className="print-div-part2 br2">
            <h6 className="p5 bb0">
              Machine Hours:
              <span>{tableData.attributes && tableData.attributes.machineHours}</span>
            </h6>
          </div>
          <div className="print-div-part2 ">
            <h6 className="p5 bb0">
              Labour Hours:
              <span>{tableData.attributes && tableData.attributes.labourHours}</span>
            </h6>
          </div>
        </div>
        <div className="print-header">
          <div className="print-div-part2 br2">
            <h6 className="p5 bb0">
              Current Group:
              <span>
                {tableData.attributes &&
                  tableData.attributes.currentGroup.data &&
                  tableData.attributes.currentGroup.data.attributes.groupCode +
                    ' - ' +
                    tableData.attributes.currentGroup.data.attributes.description}
              </span>
            </h6>
          </div>
          <div className="print-div-part2 ">
            <h6 className="p5 bb0">
              Current Operation:
              <span>
                {tableData.attributes &&
                  tableData.attributes.currentOperation.data &&
                  tableData.attributes.currentOperation.data.attributes.operationCode +
                    ' - ' +
                    tableData.attributes.currentOperation.data.attributes.description}
              </span>
            </h6>
          </div>
        </div>
        <div className="print-header">
          <div className="print-div-part2 br2">
            <h6 className="p5 bb0">
              Next Group:
              <span>
                {tableData.attributes &&
                  tableData.attributes.nextGroup.data &&
                  tableData.attributes.nextGroup.data.attributes.groupCode +
                    ' - ' +
                    tableData.attributes.nextGroup.data.attributes.description}
              </span>
            </h6>
          </div>
          <div className="print-div-part2 ">
            <h6 className="p5 bb0">
              Next Operation:
              <span>
                {tableData.attributes &&
                  tableData.attributes.nextOperation.data &&
                  tableData.attributes.nextOperation.data.attributes.operationCode +
                    ' - ' +
                    tableData.attributes.nextOperation.data.attributes.description}
              </span>
            </h6>
          </div>
        </div>
        <div className="print-header bb0">
          <div className="print-div-part2">
            <h6 className="p5 bb0">
              Remarks:
              {tableData.attributes &&
                tableData.attributes.remarks.map((dd) => <span key={randomKey()}>{dd.value}</span>)}
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};
export default PrintJobCard;
